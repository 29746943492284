html,
body,
#root,
.ant-layout,.roboto {
  // height: 100%;
  font-family: 'Roboto', sans-serif;
}
.ant-layout {
  background-color: @body-bg !important;
}
.icon-8,
.icon-10,
.icon-11,
.icon-12,
.icon-13,
.icon-14,
.icon-15,
.icon-16,
.icon-18,
.icon-20,
.icon-24,
.icon-28,
.icon-32,
.icon-40,
.icon-60,
.icon-80,
.icon-90,
.icon-100,
.icon-150 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  height: 100%;

  svg {
    display: block;
    width: 100%;
    fill: currentColor;
    height: 100%;
  }
}

.icon-8 {
  width: 8px;
  height: 8px;
}
.icon-10 {
  width: 10px;
  height: 10px;
}

.icon-11 {
  width: 11px;
  height: 11px;
}

.icon-12 {
  width: 12px;
  height: 12px;
}

.icon-13 {
  width: 13px;
  height: 13px;
}

.icon-14 {
  width: 14px;
  height: 14px;
}

.icon-15 {
  width: 15px;
  height: 15px;
}

.icon-16 {
  width: 16px;
  height: 16px;
}

.icon-18 {
  width: 18px;
  height: 18px;
}

.icon-20 {
  width: 20px;
  height: 20px;
}

.icon-24 {
  width: 24px;
  height: 24px;
}

.icon-28 {
  width: 28px;
  height: 28px;
}

.icon-32 {
  width: 32px;
  height: 32px;
}

.icon-40 {
  width: 40px;
  height: 40px;
}

.icon-60 {
  width: 60px;
  height: 60px;
}

.icon-80 {
  width: 80px;
  height: 80px;
}

.icon-90 {
  width: 90px;
  height: 90px;
}

.icon-100 {
  width: 100px;
  height: 100px;
}

.icon-150 {
  width: 150px;
  height: 150px;
}

.svg-icon-hide {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.body-container {
  background-color: #eef1f8;
  padding: 16px 16px;
  overflow: auto;
  > * {
    height: 100%;
  }
}
.custom-container {
  width: 100% !important;
  max-width: 1376px !important;
  margin: 0 auto;
  padding: 0 16px;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
/* Let's get this party started */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: #b2b2b2;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}
.main-container {
  > div {
    > div {
      overflow: auto;
      margin-bottom: 30px;
    }
  }
}

.min-w-0 {
  min-width: 0;
}

.disabled{
  opacity: 0.3;
}
.r-regular{
  font-weight: 400 !important;
}
.r-medium{
  font-weight: 500 !important;
}
.r-bold{
  font-weight: 700 !important;
}
.fs-12{
  font-size: 12px;
}
.fs-14{
  font-size: 14px;
}
.fs-16{
  font-size: 16px;
}
.fs-20{
  font-size: 20px;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  display: none;
}
.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus{
  border: 0;
}
.ant-input:focus, .ant-input-focused{
  box-shadow: inherit;
}
.ant-menu-item a::before{
  content: inherit;
}
.ant-table-thead tr th{
  background-color: inherit !important;
  font-weight: 700;
  padding: 18px 18px !important;
  &::before{
    display: none;
  }
}
.ant-table{
  border-radius: 8px !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .16);
  @media only screen and (max-width:1199px){
    &{
      box-shadow: none;
    }
  }
}
.icon_circle{
  width: 33px;
  height: 33px;
  background-color: @icn-bg !important;
  border-radius: 50%;
  color: @primary !important;
}
.small_icon{
  background: @primary !important;
  border-color: @primary !important;
  color: @primary-invert !important;
  min-width: 17px;
  width: 17px;
  height: 17px;
  line-height: 25px;
}
.inp_style,.slct_style .ant-select-selector,.phn_inp_style input{
  background: @input-bg !important;
  border-radius: @border-radius !important;
  color: @primary;
  border-color: @input-bg !important;
}
.main_btn{
  background: @primary !important;
  border-radius: @border-radius !important;
  color: @primary-invert !important;
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
  border: 0 !important;
}
.cancel_btn{
  background: transparent !important;
}
.ant-input:hover,.ant-input:focus-within,.ant-input-password:hover,.ant-input-password:focus-within{
  border-color: @border-color !important;
}
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
  box-shadow: none;
}
.large_icn_bg{
  height: 88px;
  min-width: 88px;
  max-width: 88px;
  overflow: hidden;
  background: @icn-bg;
  color: @inactive;
}
.link_text{
  color: @primary !important;
}
.border-radius{
  border-radius: @border-radius !important;
}
.modal_close{
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: @icn-bg !important;
  color: @primary !important;
  margin-top: 13px;
}
.ant-card{
  border-radius: @border-radius;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
}
.small_icon_bg{
  background: @input-bg !important;
  border-color: @input-bg !important;
  color: @primary !important;
}
.ant-table-tbody tr td{
  padding: 8px 18px !important;
  @media only screen and (max-width:1199px){
    &{
      padding: 30px 18px 8px !important;
    }
  }
}
.ant-table-measure-row{
  display: none !important;
}
.ant-notification-notice{
  background: @dark;
  color: @primary-invert;
  border-radius: @border-radius;
  padding: 15px 60px 15px 15px;
}
.ant-notification-notice-close{
  top: 24px;
  button{
    color: @primary-invert !important;
  }
}
.ant-notification-notice-message{
  color: @primary-invert;
}
.ant-upload.ant-upload-select-picture-card{
  height: 88px;
  width: 88px;
  background: @icn-bg;
  color: @secondary;
  border-radius: 50%;
  margin: 0;
  border: 0 !important;
  overflow: hidden;
}
::placeholder {
  color: @inactive !important;
}
:-ms-input-placeholder {
 color: @inactive !important;
}
::-ms-input-placeholder {
 color: @inactive !important;
}
.ant-form-item-has-error :not(.ant-input-disabled).ant-input{
  background: @input-bg !important;
}
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #dedede inset;
  transition: background-color 5000s ease-in-out 0s;
}
input {
  filter: none;
  height: 40px;
}
.ant-notification-notice-icon{
  line-height: 18px !important;
  font-size: 22px !important;
}
.cancel_btn,.ant-select-selector{
  height: 40px !important;
}
.vh_icn{
  object-fit: contain !important;
}
.bg_none_search{
  background-color: transparent;
}
.r-side-info{
  color: @secondary !important;
}
.ant-select-selection-placeholder{
  color: @primary;
}
.ant-select-selection-placeholder,.ant-select-selection-item{
  line-height: 40px !important;
}
.ant-select-arrow{
  color: @primary;
}
.ant-select-selector{
  box-shadow: inherit !important;
}
.ant-spin-dot-item{
  background-color:@primary;
}
.ant-modal-content,.ant-modal-header{
  border-radius: @border-radius;
}
.ant-upload.ant-upload-drag{
  background: @input-bg;
}
.ant-modal-content{
  box-shadow: 0px 8px 16px #00000052;
}
.c_main_wrap{
  padding-right: 230px !important;
  // @media only screen and (max-width:1600px){
  //   &{
  //     padding-right: 230px !important;
  //   }
  // }
  @media only screen and (max-width:1199px){
    &{
      padding-right: 8px !important;
    }
  }
}
.c_main_wrap_collapse{
  padding-right: 30px !important;
}
.r_side_wrapper{
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 33px;
  background: transparent !important;
  // width: 285px;
  // @media only screen and (max-width:1600px){
  //   &{
  //     width: 230px;
  //   }
  // }
  @media only screen and (max-width:1199px){
    &{
      position: relative;
      width: auto;
    }
  }
  .ant-layout-sider-zero-width-trigger{
    width: 33px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 0 0 8px;
    background: @primary;
    color: @white;
    left: 0px;
    top: 106px;
  }
  .ant-layout-sider-children{
    padding-left: 17px;
    padding-top: 100px;
    background: @white;
    border-left: 1px solid @border-color;
  }
}
.ant-breadcrumb{
  font-weight: 500;
  a:hover{
    color: @secondary;
  }
}
.anticon.ant-input-clear-icon{
  font-size: 16px;
  color: @secondary;
}
.label_inactive{
  background: @gray;
  color: @secondary;
  padding: 3px 8px;
}
.link_dark{
  color: @primary !important;
}
.delete-btn{
  color: @red !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  .small_icon{
      background: @red !important;
      border-color: @red !important;
  }
}
.label_success{
  background: @active-bg;
  color: @green;
  padding: 3px 8px;
}
.label_warning{
  background: @warning-bg;
  color: @yellow;
  padding: 3px 8px;
}
.label_danger{
  background: @danger-bg;
  color: @red;
  padding: 3px 8px;
}
.fullscreen_icon{
  width: 44px;
  height: 44px;
  background: @gray !important;
  border: 0 !important;
  color: @primary !important;
}

//calendar style

.date_picker{
  height: 40px;
  width: 100%;
  box-shadow: none;
}
.date_picker_drp_dwn{
  .ant-picker-header{
    padding: 0 21px;
    button{
      color: @primary;
    }
  }
  .ant-picker-panel-container{
    border-radius: @border-radius;
    border: 1px solid @border-color;
    box-shadow: 0px 2px 2px #00000029;
  }
  .ant-picker-cell-in-view{
    color: @secondary;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today{
    color: @primary-invert;
    .ant-picker-cell-inner{
      &::before{
        border: 0;
        border-radius: 50%;
        background: @primary;
        z-index: -1;
      }
    }
  }
  .ant-picker-cell .ant-picker-cell-inner{
    border-radius: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
    background: @primary;
  }
}

//calendar style end

.body_layout{
  margin-left: 230px;
  margin-top: 72px;
  @media only screen and (max-width:1199px){
    &{
      margin-left: 0px;
    }
  }
}
@media only screen and (min-width:1199px){
.side_menu_collapse{
  .body_layout{
    margin-left: 50px !important;
  }
}
.ant-tooltip-inner{
  a{
    color: @white !important;
    span{
      margin-right: 12px !important;
    }
  }
}
.ant-menu-submenu-popup{
  .ant-menu-item{
    &:hover{
      background: @off-white !important;
    }
    .ant-menu-title-content{
      a{
        display: block !important;
        &:hover{
          color: @primary !important;
          font-weight: 700 !important;
        }
      }
    }
  }
}
}

.primary{
  color: @primary !important;
}
.secondary{
  color: @secondary !important;
}
.border-bottom{
  border-bottom: 1px solid @border-color !important;
}
.border-color{
  border: 1px solid @border-color;
}
.text-ellipsis{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.inactive{
  color: @inactive;
}
.border-left{
  border-left: 1px solid @border-color;
}
.off-white-bg{
  background: @off-white;
}
.red{
  color: @red !important;
}

/*...Common Modal design...*/

.del_modal_wrap{
  .anticon-exclamation-circle{
      display: none;
  }
  .ant-modal-confirm-title{
      font-size: 24px;
      font-weight: 700;
  }
  .ant-modal-body{
      padding: 20px;
  }
  .ant-modal-confirm-content{
      margin: 0 !important;
      color: @secondary;
      font-size: 16px;
  }
  .ant-modal-confirm-btns{
      float: left;
      display: flex;
      flex-direction: row-reverse;
      .ant-btn {
          margin: 0 !important;
          height: 40px;
          font-size: 16px;
          font-weight: 700;
          border-radius: @border-radius;
          background: @red;
          border: 0;
      }
  }
}
.sign_out_modal{
  .ant-modal-confirm-btns{
    float: right;
    .ant-btn {
      background: @primary;
      margin-left: 10px !important;
      color: @primary-invert;
    }
  }
}
.border-top{
  border-top: 1px solid @border-color !important;
}
.inp_style.date_picker{
  .ant-picker-input{
    ::placeholder {
      color: @primary !important;
      opacity: 1 !important;
    }
  }
}

/*...checkbox style...*/

.chkbox_style{
  .ant-checkbox-checked{
    .ant-checkbox-inner{
      background-color: @primary;
      border-color: @primary;
    }
  }
  .ant-checkbox-checked::after{
    border: 1px solid @primary;
  }
}
.chkbox_style.ant-checkbox-wrapper:hover .ant-checkbox-inner, .chkbox_style.ant-checkbox:hover .ant-checkbox-inner,
.chkbox_style.ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: @primary !important;
}
.srch_slct{
  .ant-select-selection-search{
    input{
      height: 100% !important;
    }
  }
}
.slct_drp_dwn{
  box-shadow: 0px 2px 2px #00000029;
  border: 1px solid #E3E3E3;
  .ant-select-item-option-selected,.ant-select-item-option-active{
    background-color: @input-bg;
  }
}
.icn_bg_44{
  height: 44px;
  width: 44px;
  min-width: 44px;
  background: @gray;
}

/*...timelinecard...*/

.timeline_card{
  .t_card_list{
    &::after{
      height: 1px;
      width: calc(100% - 64px);
      background: #e3e3e3;
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .t_card_list:last-child::after{
    display: none;
  }
}
.tab_section:hover{
  color: @primary;
}
.tab_active{
  color: @primary !important;
  border-left: 1px solid @primary !important;
}
.section_off_set::before{
  display: block; 
  content: " "; 
  margin-top: -80px; 
  height: 80px; 
  visibility: hidden; 
  pointer-events: none;
}
.detail_card_pad{
  .ant-card-body{
      padding: 18px;
  }
}
.doc_download_brd{
  border-bottom: 1px solid  @border-color;
  margin-bottom: 10px;
  padding-bottom: 10px;
  &:last-child{
    border-bottom: 0;
    margin: 0;
    padding: 0;
  }
}
.doc_name_width{
  width: 160px;
  @media only screen and (max-width:1350px){
    &{
      width: 120px;
    }
  }
  @media only screen and (max-width:1199px){
    &{
      width: 160px;
    }
  }
}
.contract_progress{
  .ant-progress-inner{
    border-radius: 8px;
  }
  .ant-progress-bg{
    border-radius: 8px 0 0 8px;
  }
}
.mandatory{
  .ant-form-item-label{
    label{
      text-transform: uppercase;
      &::after{
        content: '*';
        display: inline-block;
        color: @red;
      }
    }
  }
}
.upload-action{
  .ant-upload{
    display: flex;
    align-items: center;
  }
}

/*...model style...*/

.veh_color{
  min-width: 16px;
  height: 16px;
  background: #000000;
}
.model_brd_btm:last-child,.last_brd_0:last-child{
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
}
.veh_card{
  height: 242px;
  @media only screen and (max-width:578px){
    &{
      height: 100%;
      padding: 20px; 
    }
  }
}
.cursor-context-m{
  cursor: default !important;
}
.model_card{
  width: 242px;
  margin: 0 12px 10px 0;
  .ant-card-cover{
      background: @card-bg;
      border-radius: @border-radius;
      padding: 0;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
      margin-bottom: 12px;
  }
  @media only screen and (max-width:578px){
      &{
          width: 100%;
      }
  }
}
.w-max-content{
  width: max-content;
}
.green_bg{
  background: @green !important;
}
.red_bg{
  background: @red !important;
}
.yellow_bg{
  background: @yellow !important;
}
.white{
  color: @white !important;
}
.table_wrapper{
  .ant-table-expanded-row-fixed{
    width: auto !important;
  }
}
.alert_wrap{
  height: 72px;
  width: calc(100% - 588px);
  bottom: 0;
  z-index: 10;
  @media only screen and (max-width:1600px){
    &{
      width: calc(100% - 500px);
    }
  }
  @media only screen and (max-width:1199px){
    &{
      width: calc(100% - 269px);
    }
  }
  .alert_close{
    background: rgba(255, 255, 255, 0.24) !important;
    right: 20px;
  }
}
.color_circle{
  width: 16px;
  height: 16px;
  background: #000000;
  border: solid 1px @border-color;
}
.disabled_btn{
  button{
    color: #ff4141 ;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    display: flex;
    align-items: center;
    font-weight: 700 !important;
    padding: 0;
  }
}
.lh_0{
  line-height: 0;
}
.border-right{
  border-right: 1px solid @border-color;
}
.custom_tab{
  .ant-tabs-nav{
    padding: 0 18px;
    &::before{
      display: none;
    }
    .ant-tabs-nav-wrap{
      background: @off-white;
      border-radius: @border-radius;
      .ant-tabs-nav-list{
        width: 100%;
        padding: 4px;
        .ant-tabs-ink-bar{
          background: inherit;
        }
        .ant-tabs-tab-active{
          background: @primary;
          border-radius: @border-radius;
          box-shadow: 0px 2px 2px #00000029;
          .ant-tabs-tab-btn{
            color: @primary-invert;
          }
        }
        .ant-tabs-tab{
          width: 33.33%;
          padding: 0;
          margin: 0;
          text-align: center;
          .ant-tabs-tab-btn{
            width: 100%;
            padding: 5px 0;
            color: @primary;
          }
        }
        .ant-tabs-tab-active{
          background: @primary;
          border-radius: @border-radius;
          .ant-tabs-tab-btn{
            color: @primary-invert !important;
          }
        }
      }
    }
  }
}
.cmn_modal_wrapper{
  .ant-modal-header{
      padding: 18px 18px 0 18px;
      border-bottom: 0;
      .ant-modal-title{
          font-weight: 700;
          font-size: 24px;
      }
  }
  .ant-modal-body{
      padding: 18px;
  }
  .ant-modal-footer{
    border-top: 0;
  }
}
.h-40{
  height: 40px;
}
.area_ht{
  height: 100px !important;
}
.custom_tab_v2{
  .ant-tabs-nav{
    justify-content: space-between;
    padding: 0;
    flex-direction: row-reverse;
    .ant-tabs-nav-wrap{
      max-width: 230px;
      height: max-content;
      .ant-tabs-nav-list{
        .ant-tabs-tab{
          width: 50%;
        }
      }
    }
  }
}
.custom_tab_pad{
  .ant-tabs-nav{
    padding: 0;
  }
}
.custom_tab_info{
  .ant-tabs-nav{
    padding: 0 18px;
    &::before{
      display: none;
    }
    .ant-tabs-nav-wrap{
      background: @off-white;
      border-radius: @border-radius;
      .ant-tabs-nav-list{
        width: 100%;
        padding: 4px;
        .ant-tabs-ink-bar{
          background: inherit;
        }
        .ant-tabs-tab-active{
          background: @primary;
          border-radius: @border-radius;
          box-shadow: 0px 2px 2px #00000029;
          .ant-tabs-tab-btn{
            color: @primary-invert;
          }
        }
        .ant-tabs-tab{
          width: 50%;
          padding: 0;
          margin: 0;
          text-align: center;
          .ant-tabs-tab-btn{
            width: 100%;
            padding: 5px 0;
            color: @primary;
          }
        }
        .ant-tabs-tab-active{
          background: @primary;
          border-radius: @border-radius;
          .ant-tabs-tab-btn{
            color: @primary-invert !important;
          }
        }
      }
    }
  }
}
.table_srch{
  max-width: 335px;
  .ant-input-affix-wrapper{
      background: none !important;
      border: 0 !important;
      border-bottom: 1px solid @border-color !important;
      input{
          background: none !important;
      }
      .ant-input-prefix{
          margin-right: 10px;
      }
  }
  .ant-input-group-addon{
      position: absolute;
      top: 0;
      bottom: 0;
      width: 23px;
      opacity: 0;
      z-index: 2;
  }
}
.num_fnt_size{
  font-size: 72px !important;
  @media only screen and (max-width:575px){
    &{
      font-size: 48px !important;
    }
  }
}
.green{
  color: @green !important;
}
.blue{
  color: @blue !important;
}
.yellow{
  color: @yellow !important;
}
.grey{
  color: @inactive !important;
}
.primary_bg{
  background: @primary !important;
}
.number_icn{
  height: 26px;
  min-width: 26px;
  border-radius: 4px;
}
.gray{
  color: @gray;
}
.unit{
  right: 16px;
  height: 100%;
  line-height: 40px;
}
.cust_radio_btn{
  .ant-radio-wrapper{
    color: @primary;
    font-size: 16px;
    .ant-radio-input{
      width: 20px;
      height: 20px;
    }
    .ant-radio-inner{
      width: 20px;
      height: 20px;
      border-color: @inactive;
    }
    .ant-radio-inner::after{
      width: 20px;
      height: 20px;
      top: -1px;
      left: -0.5px;
      background-color: @primary;
      transform: scale(0.6);
      margin: 0;
      border-radius: 50%;
    }
  }
}
.cmn_slider{
  .ant-slider-rail{
    height: 12px;
    border-radius: 25px;
    background: @gray;
  }
  .ant-slider-step{
    height: 12px;
  }
}
.info_ht{
  min-height: 550px;
  height: max-content;
}
.vehicle_img_size{
  height: 393px !important;
  max-width: 350px !important;
}
.pb-65{
  padding-bottom: 65px;
}
.gray_bg{
  background: @gray !important;
}
.radio_tab{
  padding: 4px;
  max-width: 230px;
  .ant-radio-button-wrapper-checked{
    background: @primary !important;
    color: @primary-invert !important;
    border-radius: @border-radius !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
    background: none !important;
  }
  .ant-radio-button-wrapper{
    color: @secondary;
    line-height: 32px;
  }
}
.empty_lot_ht{
  height: 500px;
  @media only screen and (max-width:1199px){
    &{
      height: 300px;
    }
  }
}
.sec_info_pad{
  padding-left: 104px;
}
.sec_info_img{
  top: 0;
  left: 0;
}
.lg_icon_info_ht{
  height: 88px;
  @media only screen and (max-width:575px){
    &{
      height: auto;
    }
  }
}
.exo_fnt{
  font-family: 'Exo 2', sans-serif;
}
.plan_info_pad,.plan_info_renew_pad{
  padding-left: 125px;
}
.plan_info_img{
  width: 101px;
  height: 119px;
  border-radius: 22px;
  background: transparent linear-gradient(141deg, #00000000 0%, #0000001F 100%) no-repeat;
  font-size: 99px;
  color: rgba(175, 175, 175, 0.32);
  border: 1px solid #F2F2F2;
  sub{
    bottom: 37px;
    right: 12px;
  }
}
.fs-60{
  font-size: 60px;
}
.warning_bg_sec{
  background: rgba(241, 170, 29,0.12);
}
.danger_bg_sec{
  background: rgba(255, 65, 65,0.12);
}
.icn_cir_wdt{
  min-width: 32px;
  min-height: 32px;
}
.secondary_bg{
  background: @secondary !important;
}
.white_bg{
  background: @white !important;
}
.cir_outline{
  outline: 10px solid rgba(255,255,255,0.4);
}
.radio_tab_v2{
  max-width: 460px;
}
.w-33{
  width:33.33%;
}
.inp_transparent{
  input{
    background: transparent;
  }
}
.pay_icn_size{
  min-width: 47px;
  height: 47px;
}
.cir_y_outline{
  outline: 10px solid rgba(241, 170, 29, 0.4);
}
.cir_g_outline{
  outline: 10px solid rgba(38, 214, 91, 0.4);
}
.ser_req_inp{
  .ant-input-number-handler-wrap{
    display: none;
  }
  .ant-input-number-input-wrap,.ant-input-number-input{
    height: 100%;
  }
}
.icn_32{
  min-width: 32px;
  min-height: 32px;
}
.table_card_title{
  box-shadow: none;
  .ant-card-head{
      padding: 0 18px;
      border: 0;
      .ant-card-head-title{
          padding-bottom: 0;
          font-size: 24px;
          font-weight: 700;
      }
  }
  .ant-card-body{
      padding: 0;
      @media only screen and (max-width:1199px){
        &{
          padding: 0 16px;
          margin-top: 16px;
        }
      }
  }
}
.ps-60{
  padding-left: 60px;
}
.client_plan_tab {
  max-width: 350px;
}
.action_btn{
  min-width: 28px;
  height: 28px;
}
.box_shadow{
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
}
.m_width_518{
  max-width: 518px;
}
.tab_img_gallery{
  height: 56px;
  width: 56px;
}
.active_veh_brd{
  border: 1px solid @primary;
}
.min-ht-450{
  min-height: 450px;
}
.park_img_tab{
  .ant-tabs-nav{
    padding: 0;
  }
}
.park_unpark_up_image{
  width: 97px;
  min-width: 97px;
  min-height: 97px;
}
.up_img_close{
  top: -4px;
  right: -4px;
}
.upload_park_unpark_icon{
  bottom: 24px;
  right: 24px;
}
.search_pr_pic {
  width: 35px;
  height: 35px;
  object-fit: cover;
}
.z-99{
  z-index: 99;
}
.ext_cont_style{
  .ant-tabs-extra-content{
    width: 50%;
    @media only screen and (max-width:575px){
      &{
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
  @media only screen and (max-width:575px){
    &{
      .ant-tabs-nav{
        flex-direction: column-reverse;
      }
    }
  }
}

.custom_slider{
  width: 200px;
  @media only screen and (max-width:575px){
    &{
      width: 180px;
    }
  }
  @media only screen and (max-width:320px){
    &{
      width: 170px;
    }
  }
  .ant-slider-mark-text{
    width: max-content;
    transform: inherit !important;
    @media only screen and (max-width:575px){
      &{
        transform: translateX(-50%) !important;
      }
    }
  }
  .ant-slider-dot{
    width: 20px;
    height: 20px;
    top: -8px;
    border: 4px solid #E3E3E3;
  }
  .ant-slider-handle{
    width: 20px;
    height: 20px;
    transform: translateX(-22%) !important;
    top: 1px;
    border: 0;
  }
  .ant-slider-dot-active{
    border: inherit;
    background: #26D65B;
  }
  .ant-slider-mark{
    top: 20px;
  }
}
.custom_inp_number{
  .ant-input-number-input-wrap{
    height: 100%;
    &{
      input{
        height: 100%;
      }
    }
  }
}
.slct_style{
  .ant-select-selection-placeholder{
    color: @inactive !important;
  }
}
.phn_inp_style{
  input{
    border: 0;
    padding: 0 11px;
    outline: none;
  }
}
.min-w-13{
  min-width: 13px;
}
.resp_req_btn{
  button{
    background: transparent !important;
    border: 0 !important;
    padding: 0 !important;
    color: @secondary !important;
    font-weight: 700 !important;
  }
}

@media only screen and (max-width:1550px){
  .radio_tab_resp{
    max-width: 180px;
  }
  .srch_p_resp{
    .table_srch{
      max-width: 150px;
    }
  }
}
@media only screen and (min-device-width: 991px) and (max-device-width: 1550px){
  .brd-r-xl-none{
    border:0;
  }
}
@media only screen and (max-width:1350px){
  .start_btn_resp{
    width: 100%;
    padding: 0 4px !important;
  }
}
@media only screen and (max-width:1199px){
  .open{
    display: block !important;
  }
  .close{
    display: none;
  }
  .start_btn_resp{
    width: auto;
    padding: 0 16px !important;
  }
  .w-lg-auto{
    width: auto !important;
  }
}
@media only screen and (max-width: 1024px){
  .table_wrapper{
    .ant-table{
      background: transparent;
      border: 0;
      box-shadow: none;
      .ant-table-tbody{
        display: flex;
        flex-wrap: wrap;
      }
      tr{   
        border: 0;
        width: 50%;
        padding: 5px;
        margin-bottom: 5px;
        td{
          background: #fff;
          box-shadow: 0 2px 2px rgba(0, 0, 0, .16);
          &:first-child{
            border-radius: 8px 8px 0 0;
          }
          &:last-child{
            border-radius: 0 0 8px 8px;
          }
        }
      }
    }
    .ant-table-content{
      overflow: inherit;
      table{
        width: auto !important;
      }
    }
    .ant-table-container{
      &::after{
        box-shadow: inherit !important;
      }
    }
  }
  .ins_table_wrapper{
    .ant-table{
      tr{
        box-shadow: none;
        td{
          background: @off-white;
          border-bottom: 1px solid @border-color;
        }
      }
      .ant-table-placeholder{
        td{
          background: inherit !important;
          box-shadow: none !important;
          border: 0 !important;
        }
      }
    }
  }
  .emp_detail_activities{
    background: @white;
    border-radius: @border-radius;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
    padding: 8px;
  }
}
@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
  .table_wrapper{
    table, thead, tbody, th, td, tr { 
      display: block; 
    }
    thead{
      tr{
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }
    tr {
      border: 1px solid #ccc;
      margin-bottom: 16px;
      border-radius: 8px;
      overflow: hidden;
    }
    td { 
      border: none;
      border-bottom: 1px solid #eee; 
      position: relative;
      padding-left: 50%; 
      display: flex;
      justify-content: flex-start;
      min-height: 40px;
      &::before{
        position: absolute;
        top: 8px;
        left: 18px;
        width: 45%; 
        padding-right: 10px; 
        white-space: nowrap;
        font-weight: 700;
      }
    }
    .ant-table-placeholder{
      width: 100% !important;
      td{
        display: flex;
        justify-content: center;
        border-radius: @border-radius !important;
        &::before{
          display: none;
        }
      }
    }
  }
}
@media only screen and (max-width:991px){
  .fs-md-14{
    font-size: 14px !important;
  }
}
@media only screen and (max-width:768px){
  .brd-r-none{
    border: 0;
  }
}
@media only screen and (max-width:575px){
  .border-right-xs-0{
    border-right: 0;
  }
  .fs-xs-24{
    font-size: 24px !important;
  }
  .fs-xs-18{
    font-size: 18px !important;
  }
  .fs-xs-14{
    font-size: 14px !important;
  }
  // .req_cnt_card{
  //   .ant-card-body{
  //     display: flex;
  //     .order-2{
  //       margin-left: auto;
  //     }
  //   }
  // }
  .border-xs-bottom{
    border: 0 !important;
    margin: 16px 0 0 0 !important;
  }
  .ps_xs_0{
    padding-left: 0;
  }
  .position-xs-relative{
    position: relative !important;
  }
  .fs-xs-20{
    font-size: 20px !important;
  }
  .w-xs-100{
    width: 100% !important;
  }
  .h-xs-max{
    height: max-content;
  }
  .table_wrapper{
    .ant-table{
      tr{
        width: 100%;
        padding: 0;
        margin-bottom: 16px;
      }
    }
  }
  .plan_info_pad{
    padding-left: 0px;
  }
  .act_position{
    position: absolute;
    top: 0;
    right: 7px;
  }
}
@media only screen and (max-width:529px){
  .position-sm-relative{
    position: relative !important;
  }
  .plan_info_renew_pad{
    padding-left: 0px;
  }
}
@media only screen and (max-width:375px){
  .d-res-block{
    display: block !important;
  }
  .w-res-100{
    width: 100% !important;
  }
  .w-xs-50{
    width: 50% !important;
  }
}