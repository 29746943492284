@import '../../Assets/less/style.less';
.fac_counter{
    .ant-form-item-control{
        width: max-content;
        position: relative;
    }
}
.counter{
    margin: 0 30px !important;
    position: relative !important;
    width: 70px !important;
    border: 0 !important;
    box-shadow: none !important;
    .ant-input-number-handler-wrap{
        opacity: 1;
        position: absolute;
        left: 115px;
        .ant-input-number-handler{
            border: 0;
            .anticon{
                opacity: 0;
            }
        }
        .ant-input-number-handler-up{
            position: absolute;
            right: 30px;
            top: 8px;
            height: 20px;
            width: 20px;
        }
        .ant-input-number-handler-down{
            position: absolute;
            right: 155px;
            top: 8px;
            height: 20px;
            width: 20px;
        }
    }
    input{
        background: @input-bg;
        height: 40px;
        border-radius: @border-radius;
        text-align: center;
        color: @primary;
    }
}
.fac_count_picon{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    background: @gray;
    position: absolute;
    top: 38px;
    text-align: center;
    line-height: 20px;
    color: @secondary;
}
.fac_count_plus{
    left: 126px;
}
.fac_count_minus{
    left: 0px;
}
.fac_location_icn{
    top: 38px;
    right: 15px;
    color: #777777;
}
