.setting_inp{
    max-width:365px;
    &::after{
        content: 'KD/ Day';
        position: absolute;
        top: 0;
        right: 25px;
        bottom: 0;
        height: 100%;
        display: flex;
        align-items: center;
        color: #bbb;
        font-size: 16px;
        @media only screen and (max-width:575px){
            &{
                font-size: 14px;
                right: 10px;
            }
        }
    }
}
