@import '../../Assets/less/style.less';
.clnt_detail{
    .coming-soon{
        top: 30%;
        left: 35%;
    }
}

@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.client_detail_listing{
		td:nth-of-type(1):before { content: "Plan ID"; }
		td:nth-of-type(2):before { content: "Plan Type"; }
		td:nth-of-type(3):before { content: "Plan term"; }
		td:nth-of-type(4):before { content: "Vehicle"; }
		td:nth-of-type(5):before { content: "Due Date"; }
	}
	.client_detail_st_listing{
		td:nth-of-type(1):before { content: "Plan ID"; }
		td:nth-of-type(2):before { content: "Plan Type"; }
		td:nth-of-type(3):before { content: "Plan term"; }
		td:nth-of-type(4):before { content: "Vehicle"; }
		td:nth-of-type(5):before { content: "Status"; }
	}
    .client_veh_p_listing{
		td:nth-of-type(1):before { content: "Manufacturer"; }
		td:nth-of-type(2):before { content: "Model"; }
		td:nth-of-type(3):before { content: "Year"; }
		td:nth-of-type(4):before { content: "Color"; }
		td:nth-of-type(5):before { content: "Plan"; }
        td:nth-of-type(6):before { content: "Number"; }
	}
	.client_veh_sh_listing{
		td:nth-of-type(1):before { content: "Manufacturer"; }
		td:nth-of-type(2):before { content: "Model"; }
		td:nth-of-type(3):before { content: "Color"; }
		td:nth-of-type(4):before { content: "Plan"; }
        td:nth-of-type(5):before { content: "Number"; }
		td:nth-of-type(6):before { content: "Client"; }
        td:nth-of-type(7):before { content: "Access"; }
	}
	.transaction_listing{
		td:nth-of-type(1):before { content: "Transactions"; }
		td:nth-of-type(2):before { content: "Date"; }
		td:nth-of-type(3):before { content: "Time"; }
		td:nth-of-type(4):before { content: "Amount"; }
        td:nth-of-type(5):before { content: "Invoice"; }
	}
}
