@import '../../Assets/less/style.less';
.dash_exp_ov_scroll{
    height: 170px;
    overflow-y: auto;
}
.fac_cap_progress{
    height: 236px;
}
.map_wrapper{
    height: 258px;
    @media only screen and (max-width:576px){
        &{
            height: auto;
        }
    }
}
.dash_card_resp_fnt{
    h3{
        font-size: 24px;
        @media only screen and (max-width:1350px){
            &{
                font-size: 20px;
            }
        }
        @media only screen and (max-width:575px){
            &{
                font-size: 18px;
            }
        }
    }
}

.dash_view_btn{
    min-width: 17px;
    width: 17px;
    height: 17px;
    line-height: 25px;
}
