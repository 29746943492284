@import '../../Assets/less/style.less';
.srt_arw_up {
    transform: rotateX(180deg);
    margin-left: 8px;
    color: @secondary;
}
.srt_arw_down{
    transform: rotateX(0deg);
    margin-left: 8px;
    color: @secondary;
}
.srt_arw_position{
    position: absolute;
    right: 0;
    margin-top: 6px;
}
